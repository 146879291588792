
import { Config } from 'client-website-ts-library/services';
import { Context, ContextItemType } from 'client-website-ts-library/types/Context';
import { Component, Vue } from 'vue-property-decorator';

import Form from '../components/Forms/Form.vue';

@Component({
  components: {
    Form,
  },
})
export default class LeaseAppraisal extends Vue {
  private formData: Context | null = null;

  mounted() {
    this.formData = {
      Items: [{
        Type: ContextItemType.EnquiryTargetProfile,
        Id: '612d84b4-0122-4ab2-bea8-2c6175d3acf0',
      }],
      ClientWebsiteId: Config.Website.Id,
      WebsiteId: Config.Website.Settings!.WebsiteId,
      WebsiteLevel: Config.Website.Settings!.WebsiteLevel,
    };
  }
}
